<template>
  <primary-modal
    ref="editStandUp"
    modal-id="edit_stand_up"
    :title="this.isLoading ? 'Please wait' : 'Edit Stand Up'"
  > 
    <div class="edit-stand-up p-3"> 
      <b-form>
        <div class="mb-3">
          <p class="edit-stand-up__current-day m-0">{{currentDay()}}</p>
          <span class="edit-stand-up__current-date mt-2">{{currentDate()}}</span>
        </div>
        <b-form-group
          class="mb-4"
          label="What did you work on yesterday?"
          label-for="workedOn"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.previous_task"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          class="mb-4"  
          label="What will you be working on today?" 
          label-for="workingOn"
          description="Share 1-2 sentence(s) summary of your day's task or past ticket URL"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.current_task"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          class="mb-4"
          label="What issue(s) are blocking you?"
          label-for="blockers"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.blocker"
          ></b-form-textarea>
        </b-form-group>         
        <b-button block type="submit" variant="primary" class="mt-5" @click="handleSubmit">
          {{ isLoading ? "" : "Submit" }}
          <RingLoader v-if="isLoading" />
        </b-button>
      </b-form>
    </div>
  </primary-modal>
</template>
<script>
import PrimaryModal from "@/components/PrimaryModal.vue";
import RingLoader from "@/components/loader/RingLoader";
import moment from 'moment';
import { mapActions } from 'vuex'
export default {
  name: "EditStandUp",
  components: {
    PrimaryModal, 
    RingLoader,
  },

  props: {
    standup: {
      type: Object,
      required: true,
    },
  },
  
  data() {
    return {
      isLoading: false,
      form: this.standup
    };
  },

  watch: {
    standup(newStandup, oldStandup) {
      this.form = newStandup
    }
  },

  methods: {
    ...mapActions(['editStandUp']),
    currentDate(date) {
      return moment(date).format("MMMM M")
    },
    currentDay(date) {
      return moment(date).format("dddd")
    },
     handleSubmit(e) {
      e.preventDefault()
      this.isLoading = true;

      this.$store
        .dispatch("editStandUp", {
          id: this.standup.id,
          data: {
            current_task: this.form.current_task,
            previous_task: this.form.previous_task,
            blocker: this.form.blocker
          }
        })
        .then(() => {
          this.$bvModal.hide("edit_stand_up");
        })
        .catch(() => {
          //nothing to do here
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-stand-up {
  p {
      color: $grey-tint-2;
      font-size: 0.75rem;
  }
  &__current-date{
    color: #2E2D32;
    font-size: 18px
  }
}
</style>