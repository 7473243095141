<template>
  <primary-modal
    ref="addStandUp"
    modal-id="add_stand_up"
    :title="this.isLoading ? 'Please wait' : 'Add Stand Up'"
  >
    <div class="add-stand-up p-3">
      <p>
        Every weekday let your manager know about what you’re working on , your
        work plan and any blockers you have. Remember to be on time.
      </p>
      <b-form>
        <div class="mb-3">
          <p class="add-stand-up__current-day m-0">{{ currentDay() }}</p>
          <span class="add-stand-up__current-date mt-2">{{
            currentDate()
          }}</span>
        </div>
        <b-form-group
          class="mb-4"
          label="What did you work on yesterday?"
          label-for="workedOn"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.previous_task"
            placeholder="Type Here..."
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          class="mb-4"
          label="What will you be working on today?"
          label-for="workingOn"
          description="Share 1-2 sentence(s) summary of your day's task or past ticket URL"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.current_task"
            placeholder="Type Here..."
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          class="mb-4"
          label="What issue(s) are blocking you?"
          label-for="blockers"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.blocker"
            placeholder="Type Here..."
          ></b-form-textarea>
        </b-form-group>
        <b-form-group class="mb-4" label="Project" label-for="project">
          <b-form-select v-model="form.project">
            <b-form-select-option
              v-for="project in projects"
              :key="project.id"
              :value="project.id"
            >
              {{ project.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-button
          block
          type="submit"
          variant="primary"
          class="mt-5"
          @click="handleSubmit"
        >
          {{ isLoading ? "" : "Submit" }}
          <RingLoader v-if="isLoading" />
        </b-button>
      </b-form>
    </div>
  </primary-modal>
</template>
<script>
import PrimaryModal from "@/components/PrimaryModal.vue";
import RingLoader from "@/components/loader/RingLoader";
import moment from "moment";
import Dropdown from "@/components/Dropdown.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddStandUp",
  components: {
    PrimaryModal,
    RingLoader,
  },

  data() {
    return {
      isLoading: false,
      form: {
        previous_task: "",
        current_task: "",
        blocker: "",
        project: null,
      },
    };
  },

  computed: {
    ...mapGetters(["projects"]),
  },

  methods: {
    ...mapActions(["addStandUp", "fetchProject"]),
    currentDate(date) {
      return moment(date).format("MMMM DD");
    },
    currentDay(date) {
      return moment(date).format("dddd");
    },

    handleSubmit(e) {
      e.preventDefault();
      this.isLoading = true;
      console.log(this.$store.state.standup.projects);
      this.$store
        .dispatch("addStandUp", this.form)
        .then(() => {
          this.$bvModal.hide("add_stand_up");
        })
        .catch(() => {
          //nothing to do here
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  created() {
    this.fetchProject();
  },
};
</script>

<style lang="scss" scoped>
.add-stand-up {
  p {
    color: $grey-tint-2;
    font-size: 0.75rem;
  }
  &__current-date {
    color: #2e2d32;
    font-size: 18px;
  }
}
</style>