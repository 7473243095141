<template>
  <div class="stand-up">
    <div class="dashboard-summary">
      <StandUpSummary :from="filterValue" :count="standUpCount" />
    </div>
    <div class="dashboard-content py-4">
      <div class="stand-up__actions d-flex justify-content-end">
        <div class="m-width-300 mr-2">
          <SearchBox
            v-model="searchStandup"
            bg-color="#D6DDE4"
            :rounded="5"
            class="stand-up__search-standup"
            type="text"
            placeholder="Search tasks, blockers and stand up..."
          />
        </div>
        <!-- <FilterDropdown variant="faint-blue" :filters="filters" @filtered="setFilter" /> -->
        <b-button
          variant="link"
          class="ml-2 btn btn-tertiary text-decoration-none px-3"
          @click="$bvModal.show('add_stand_up')"
        >
          Add Stand Up
        </b-button>
      </div>
      <div class="stand-up__body mt-3 pb-4">
        <b-table
          striped
          hover
          responsive
          :fields="fields"
          :items="filteredStandup"
        >
          <template #cell(action)="data">
            <OptionsDropdown
              :toggle-class="['text-decoration-none']"
              :options-data="optionsData"
              :options-id="data.item.id"
              :onClickOption="(type) => handleClickOption(type, data.item.id)"
            />
          </template>
        </b-table>
        <div
          class="m-pagination overflow-auto d-flex p-4 justify-cotnent-between align-items-center"
        >
          <span class="flex-grow-1">
            Showing page {{ currentPage }} of {{ totalRows }}
          </span>
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            align="right"
            pills
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <AddStandUp />
    <EditStandUp :standup="standup" />
    <ViewStandUp :standup="standup" />
  </div>
</template>
<script>
import StandUpSummary from "../../components/stand-up/StandUpSummary.vue";
import SearchBox from "../../components/SearchBox.vue";
// import FilterDropdown from "../../components/FilterDropdown.vue";
import OptionsDropdown from "@/components/OptionsDropdown.vue";
import AddStandUp from "../../components/stand-up/AddStandUp.vue";
import EditStandUp from "../../components/stand-up/EditStandup.vue";
import ViewStandUp from "../../components/stand-up/ViewStandupDetails.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "StandUp",
  components: {
    StandUpSummary,
    SearchBox,
    // FilterDropdown,
    OptionsDropdown,
    AddStandUp,
    EditStandUp,
    ViewStandUp,
  },
  data() {
    return {
      searchStandup: "",
      filterValue: "weekly",
      perPage: 7,
      currentPage: 1,
      optionsData: [
        {
          name: "Edit",
          value: "edit",
          class: ["text-tertiary"],
        },
        {
          name: "View Details",
          value: "view",
          class: [""],
        },
        // {
        //   name: "Remove",
        //   value: "remove",
        //   class: ["text-secondary"],
        // },
      ],
      filters: [
        {
          name: "Today",
          value: "today",
        },
        {
          name: "Weekly",
          value: "weekly",
        },
        {
          name: "Monthly",
          value: "monthly",
        },
      ],
      fields: [
        {
          key: "date",
        },
        {
          key: "previous_task",
        },
        {
          key: "current_task",
        },
        {
          key: "blocker",
        },
        {
          key: "action",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["standUp", "standup", "projects"]),
    totalRows() {
      return this.standUp?.length;
    },
    standUpCount() {
      return this.standUp.length;
    },
    filteredStandup() {
      return this.standUp.filter((standup) => {
        return standup.blocker.toLowerCase().match(this.searchStandup.toLowerCase()) ||
               standup.current_task.toLowerCase().match(this.searchStandup.toLowerCase()) ||
               standup.previous_task.toLowerCase().match(this.searchStandup.toLowerCase()) ||
               standup.date.match(this.searchStandup)
      })
    }
  },
  methods: {
    ...mapActions(["fetchStandUp", "removeStandUp", "getStandUp"]),
    handleClickOption(type, id) {
      // if (type === "remove") {
      //   this.removeStandUp(id);
      // }
      if (type === "edit") {
        console.log("working");
        this.getStandUp(id);
        this.$bvModal.show("edit_stand_up");
      }
      if (type === "view") {
        this.getStandUp(id);
        this.$bvModal.show("stand-up_modal");
      }
      console.log(type, id);
    },
    setFilter(value) {
      console.log(value);
    },
  },

  created() {
    this.fetchStandUp();
  },
};
</script>
<style lang="scss" scoped>
.stand-up {
  &__body {
    background-color: $white;
    border-radius: $size-10;
  }
}
</style>
