<template>
  <b-modal id="stand-up_modal" modal-class="stand-up-modal" hide-footer>
    <template #modal-title>
      <p>
        <span class="d-block stand-up-modal__title">Date</span>
        <span class="stand-up-modal__date">{{ form.date }}</span>
      </p>
    </template>
    <p class="mb-5">
      <span class="d-block stand-up-modal__title">What did you work on yesterday?</span>
      <span class="stand-up-modal__task">
        {{ form.previous_task }}
      </span>
    </p>

    <p class="mb-5">
      <span class="d-block stand-up-modal__title">
        What will you be working on today?
      </span>
      <span class="stand-up-modal__task">
        {{ form.current_task }}
      </span>
    </p>

    <p class="mb-5">
      <span class="d-block stand-up-modal__title">What issues are blocking you?</span>
      <span class="stand-up-modal__task">
        {{ form.blocker }}
      </span>
    </p>
  </b-modal>
</template>
<script>

export default {
  name: "ViewStandUp",
  
  props: {
    standup: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: this.standup
    };
  },

  watch: {
    standup(newStandup, oldStandup) {
      this.form = newStandup
    }
  },
}
</script>

<style lang="scss" scoped>
  .stand-up-modal {
  &__date {
    color: $dark-blue;
    font-size: 0.875rem;
  }
  &__title {
    color: $faint-blue;
    font-weight: 400;
    font-size: 0.75rem;
    margin-bottom: 0.3rem;
  }

  &__task {
    color: $dark-blue;
    font-size: 0.875rem;
  }
}
</style>